import React, { useState, useMemo } from "react";
import styled from "styled-components";
import ArticleModal from "./ArticleModal"; // Ensure the correct path

// Import images
import Xarticle0 from "../../images/Xarticle0.png";
import Xarticle1 from "../../images/Xarticle1.png";
import Xarticle2 from "../../images/Xarticle2.png";
import Xarticle3 from "../../images/Xarticle3.png";
import Xarticle4 from "../../images/Xarticle4.png";
import Xarticle5 from "../../images/Xarticle5.png";
import Xarticle6 from "../../images/Xarticle6.png";
import Xarticle7 from "../../images/Xarticle7.png";
import Xarticle8 from "../../images/Xarticle8.png";
import Xarticle9 from "../../images/Xarticle9.png";
import Xarticle10 from "../../images/Xarticle10.png";
import Xarticle11 from "../../images/Xarticle11.png";
import Xarticle12 from "../../images/Xarticle12.png";
import Xarticle13 from "../../images/Xarticle13.png";
import Xarticle14 from "../../images/Xarticle14.png";
import Xarticle15 from "../../images/Xarticle15.png";
import Xarticle16 from "../../images/Xarticle16.png";
import Xarticle17 from "../../images/Xarticle17.png";
import Xarticle18 from "../../images/Xarticle18.png";
import Xarticle19 from "../../images/Xarticle19.png";
import Xarticle20 from "../../images/Xarticle20.png";
import Xarticle21 from "../../images/Xarticle21.png";
import Xarticle22 from "../../images/Xarticle22.png";
import Xarticle23 from "../../images/Xarticle23.png";
import Xarticle24 from "../../images/Xarticle24.png";
import Xarticle25 from "../../images/Xarticle25.png";
import Xarticle26 from "../../images/Xarticle26.png";
import Xarticle27 from "../../images/Xarticle27.png";
import BackgroundImage from "../../images/VoicePageBG.png";
import BottomImage from "../../images/carljungDalleIMG.png";

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  z-index: 2;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Article = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 100%; /* This maintains the square aspect ratio */
  background-color: transparent;
  border: 1px solid #45fe47;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &:hover div {
    opacity: 1;
  }
`;

const ArticleImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ArticleOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #45fe47;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ArticleButton = styled.button`
  display: ${({ show }) => (show ? "block" : "none")};
  width: 100%;
  padding: 10px;
  background-color: #45fe47;
  color: #000;
  border: none;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const FilterContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const FilterDropdown = styled.select`
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
  border: 1px solid #45fe47;
  border-radius: 5px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  color: #45fe47;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%2345fe47" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;

  &:focus {
    outline: none;
  }

  /* Hide default arrow in IE */
  &::-ms-expand {
    display: none;
  }

  /* Ensure text is visible on the new background */
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  /* Ensure the custom arrow is visible */
  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #45fe47;
    pointer-events: none;
  }
`;

// background: linear-gradient(
//   135deg,
//   rgba(255, 255, 255, 0.1),
//   rgba(255, 255, 255, 0)
// );
// backdrop-filter: blur(5px);
// -webkit-backdrop-filter: blur(10px);

const XArticlesComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeArticle, setActiveArticle] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("all");

  const articles = useMemo(
    () => [
      {
        id: 27,
        link: "https://x.com/jrq3rq/status/1842974661480710531",
        imageSrc: Xarticle27,
        title:
          "The Essence of Enchanting Retail: Bringing Back the Magic in Modern Small Shops",
        showButton: false,
        topics: [
          "retail",
          "small business",
          "customer-experience",
          "sustainability",
          "innovation",
          "technology",
          "experiential-design",
        ],
      },
      {
        id: 26, // Next available ID
        link: "https://x.com/jrq3rq/status/1842292716996276693", // Placeholder X (Twitter) link
        imageSrc: Xarticle26, // Placeholder for image source
        title:
          "Revolutionizing Play: The Integration of AI in the Toy Industry",
        showButton: false,
        topics: ["ai", "toys", "education", "personalization"],
      },
      // {
      //   id: 25, // Next available ID
      //   link: "https://x.com/jrq3rq/status/1842278726706626859", // Placeholder X (Twitter) link
      //   imageSrc: Xarticle25, // Placeholder for image source
      //   title:
      //     "Innovative Toys as Cultural Ambassadors: The Role of AI-Enhanced Marbles in Museums",
      //   showButton: false,
      //   topics: ["ai", "jungian archetypes", "museums", "toys"],
      // },
      {
        id: 24, // Next available ID
        link: "https://x.com/jrq3rq/status/1840806509292503212",
        imageSrc: Xarticle24, // Add an appropriate image
        title:
          "The Art of Being Uniquely You: Harnessing Individuality in Creativity",
        showButton: false,
        topics: [
          "creativity",
          "individuality",
          "psychology",
          "personal-development",
        ],
      },

      {
        id: 23,
        link: "https://x.com/jrq3rq/status/1827134562801218044", // Placeholder X (Twitter) link
        imageSrc: Xarticle23, // Placeholder for image source
        title:
          "Creating the Future of Experiential Spaces: A Modular, Multi-Sensory Environment",
        showButton: false,
        topics: [
          "innovation",
          "technology",
          "retail",
          "museums",
          "adaptive-experiential-design",
          "ar",
        ],
      },
      {
        id: 22,
        link: "https://x.com/jrq3rq/status/1823894563440013781",
        imageSrc: Xarticle22, // You'll need to add an appropriate image
        title:
          "The Intersection of Art and AI: Navigating New Frontiers in Creative Expression ",
        showButton: false,
        topics: ["ai", "art", "innovation"],
      },
      {
        id: 21,
        link: "https://x.com/jrq3rq/status/1818330358745874787",
        imageSrc: Xarticle21, // You'll need to add an appropriate image
        title:
          "Holographic Storytelling: Interactive Holograms for History and Art ",
        showButton: false,
        topics: ["holography", "art", "museums", "technology", "innovation"],
      },
      {
        id: 14,
        link: "https://x.com/jrq3rq/status/1813033783224070539",
        imageSrc: Xarticle14, // You'll need to add an appropriate image
        title:
          "From Retail Ruins to Museums: How to Transform America's Abandoned Malls",
        showButton: false,
        topics: ["art", "museums", "business", "innovation", "urban-planning"],
      },
      {
        id: 19,
        link: "https://x.com/12jungian/status/1811872575007654071",
        imageSrc: Xarticle19, // You'll need to add an appropriate image
        title:
          "Transforming Today's Museums into Tomorrow's Ivy League: AI as an Educational Tool",
        showButton: false,
        topics: ["ai", "museums", "education", "technology", "innovation"],
      },
      {
        id: 20,
        link: "https://x.com/12jungian/status/1812556682624643569",
        imageSrc: Xarticle20, // You'll need to add an appropriate image
        title:
          "Part 2: Beyond AI – Reshaping the Future of Museums with a Comprehensive Vision",
        showButton: false,
        topics: ["museums", "innovation", "technology", "art", "education"],
      },
      {
        id: 15,
        link: "https://x.com/jrq3rq/status/1814799275647508916",
        imageSrc: Xarticle15, // You'll need to add an appropriate image
        title:
          "The Role and Evolution of Non-Profits in America: A Comprehensive Guide",
        showButton: false,
        topics: ["business", "leadership", "social-impact", "innovation"],
      },
      {
        id: 16,
        link: "https://x.com/jrq3rq/status/1806108757203747184",
        imageSrc: Xarticle16, // You'll need to add an appropriate image
        title:
          "The Dangers of Overconfidence: How Thinking You Know Everything Can Lead to Failure",
        showButton: false,
        topics: [
          "psychology",
          "leadership",
          "business",
          "personal-development",
        ],
      },
      {
        id: 13,
        link: "https://x.com/jrq3rq/status/1809369493568909601",
        imageSrc: Xarticle13, // You'll need to add an appropriate image
        title:
          "Unlocking Potential: Mastering the Art of Constructive Questioning",
        showButton: false,
        topics: ["leadership", "psychology", "innovation", "business"],
      },
      {
        id: 17,
        link: "https://x.com/jrq3rq/status/1807206985802436854",
        imageSrc: Xarticle17, // You'll need to add an appropriate image
        title: "The Lost Magic: Rediscovering the Soul of Walt Disney's World",
        showButton: false,
        topics: ["entertainment", "business", "innovation", "art"],
      },
      {
        id: 18,
        link: "https://x.com/jrq3rq/status/1808322265102405899",
        imageSrc: Xarticle18, // You'll need to add an appropriate image
        title: "Understanding the Importance of Skill Development",
        showButton: false,
        topics: ["personal-development", "education", "business", "career"],
      },
      {
        id: 12,
        link: "https://x.com/jrq3rq/status/1805414687204688147",
        imageSrc: Xarticle11,
        title:
          "Embracing the Infinite: Psychological Patterns in Data Analysis",
        showButton: false,
        topics: ["psychology", "data-analysis"],
      },
      {
        id: 11,
        link: "https://x.com/jrq3rq/status/1804279451192299531",
        imageSrc: Xarticle9,
        title: "Embracing the Infinite: Finding Patterns in a Data-Rich World",
        showButton: false,
        topics: ["data-analysis"],
      },
      {
        id: 1,
        link: "https://x.com/12jungian/status/1797332069313569091",
        imageSrc: Xarticle1,
        title: "Unveiling the Mythic Roots: The Journey of Jungian Archetypes",
        showButton: true,
        topics: ["psychology", "jungian archetypes"],
        content: `
       <h2>Unveiling the Mythic Roots: The Journey of Jungian Archetypes</h2>
        <p>In the vast landscape of psychological exploration, few figures loom as large as Carl Gustav Jung. Jung's pioneering work delved into the depths of the human psyche, unraveling mysteries that had long confounded scholars and laypeople alike. Central to Jung's framework are his theories on archetypes, symbolic patterns deeply ingrained in the collective unconscious of humanity. This article embarks on a journey through the mythic roots of Jungian archetypes, shedding light on their origins, significance, and practical applications in contemporary society.</p>
        <h3>The Collective Unconscious</h3>
        <p>At the core of Jungian psychology lies the concept of the collective unconscious, a reservoir of shared experiences, symbols, and archetypes that transcends individuality. Within this vast repository, archetypes serve as primordial templates, shaping our perceptions, behaviors, and cultural narratives. Through exploring the collective unconscious, we gain insights into the universal aspects of the human condition, uncovering the threads that bind us together across time and space.</p>
        <h3>Mythic Origins of Archetypes</h3>
        <p>The origins of Jungian archetypes can be traced back to the myths and legends of ancient civilizations. From the hero's epic quest for glory to the shadowy depths of the underworld, mythologies from around the world abound with archetypal motifs. These timeless tales, passed down through generations, offer glimpses into the collective soul of humanity, reflecting our deepest fears, aspirations, and desires.</p>
        <h3>The Hero's Journey</h3>
        <p>Among the myriad archetypes that populate the collective unconscious, none is more iconic than the hero. Embarking on a journey of trials and tribulations, the hero confronts inner and outer challenges, ultimately emerging transformed and enlightened. Through the hero's journey, individuals find resonance with their own struggles and triumphs, discovering the courage and resilience within themselves to face life's adversities.</p>
        <h3>The Shadow and the Anima/Animus</h3>
        <p>Yet, alongside the hero, lurks the shadow—a dark and often neglected aspect of the psyche. The shadow represents the repressed and denied parts of ourselves, manifesting in unconscious patterns of behavior and projection onto others. Similarly, the anima (the feminine aspect within men) and animus (the masculine aspect within women) embody the unconscious qualities that we seek to integrate and understand.</p>
        <h3>Archetypes in Modern Culture</h3>
        <p>From the pages of literature to the silver screen, archetypes permeate modern culture, shaping our stories, symbols, and societal norms. Whether in the form of the wise mentor, the femme fatale, or the rebellious outlaw, archetypal characters resonate with audiences on a profound level, tapping into universal truths and collective experiences.</p>
        <h3>Psychological Applications of Archetypes</h3>
        <p>Beyond their cultural significance, archetypes find practical applications in psychology, therapy, and personal development. Therapists utilize archetypal imagery and storytelling to help clients explore and integrate unconscious aspects of their psyche, facilitating healing and transformation. Similarly, personality assessments and self-discovery tools draw upon archetypal frameworks to provide insights into individuals' motivations, strengths, and challenges.</p>
        <h3>Archetypes in Everyday Life</h3>
        <p>In our daily lives, we encounter archetypal patterns in myriad forms, from the nurturing caregiver to the ambitious entrepreneur. These archetypes shape our identities, relationships, and societal roles, influencing how we perceive ourselves and interact with others. By recognizing archetypal dynamics at play, we gain a deeper understanding of human behavior and collective dynamics.</p>
        <h3>The Evolution of Archetypes</h3>
        <p>As society evolves and cultural landscapes shift, archetypes adapt and evolve alongside them. New archetypal figures emerge, reflecting changing social norms, values, and aspirations. Yet, beneath the surface, the core essence of archetypes remains unchanged, serving as timeless symbols of the human experience.</p>
        <h3>Embracing the Archetypal Journey</h3>
        <p>In embracing the archetypal journey, we embark on a path of self-discovery, introspection, and personal growth. By exploring our own archetypal narratives and patterns, we gain insights into our deepest motivations, fears, and potentials. Through this process of integration and individuation, we move towards wholeness and authenticity, aligning with the transformative power of Jungian archetypes.</p>
        <h3>Critique and Counterarguments</h3>
        <p>While Jung's theories on archetypes have had a profound impact on psychology and culture, they are not without critique. Critics argue that archetypes may not be universally applicable, and their meanings may vary significantly across cultures and contexts. Some scholars emphasize the role of broader social and cultural factors that shape human behavior, suggesting that archetypes alone may not fully account for the complexity of the human experience. Despite these criticisms, Jungian archetypes continue to inspire and provoke thought, inviting further exploration and dialogue within the field of psychology and beyond.</p>
      `,
        bottomImage: BottomImage,
      },
      {
        id: 2,
        link: "https://x.com/12jungian/status/1797776932492513301",
        imageSrc: Xarticle3,
        title:
          "Unveiling the Power of Jungian Archetypes: Discovering Business Identity",
        showButton: false,
        topics: ["psychology", "jungian archetypes", "business"],
      },
      {
        id: 3,
        link: "https://x.com/12jungian/status/1798465206026391646",
        imageSrc: Xarticle2,
        title:
          "Unveiling Creative Potential: Harnessing Archetypes for Innovation and Growth",
        showButton: false,
        topics: ["psychology", "innovation"],
      },
      {
        id: 4,
        link: "https://x.com/jrq3rq/status/1791638597210435907",
        imageSrc: Xarticle0,
        title: "Enhancing AI Alignment with Jungian Archetypes",
        showButton: false,
        topics: ["ai", "psychology", "jungian archetypes"],
      },
      {
        id: 5,
        link: "https://x.com/jrq3rq/status/1803818318215962710",
        imageSrc: Xarticle5,
        title:
          "Reimagining the Past for the Future: How 2D Nostalgic Games Can Thrive in Web 3",
        showButton: false,
        topics: ["gaming", "web3"],
      },
      {
        id: 6,
        link: "https://x.com/jrq3rq/status/1796719807360127156",
        imageSrc: Xarticle4,
        title: "Revolutionizing the World: The Transformative Power of AI",
        showButton: false,
        topics: ["ai", "technology"],
      },
      {
        id: 7,
        link: "https://x.com/jrq3rq/status/1791210906015166705",
        imageSrc: Xarticle6,
        title:
          "Interactive Museum and Art Exhibits: Transforming Visitor Experience with AI Characters",
        showButton: false,
        topics: ["ai", "art", "museums"],
      },
      {
        id: 8,
        link: "https://x.com/jrq3rq/status/1801792928899285381",
        imageSrc: Xarticle7,
        title:
          "Unlocking AI's Full Potential: The Critical Role of Specialized Expertise",
        showButton: false,
        topics: ["ai", "expertise"],
      },
      {
        id: 9,
        link: "https://x.com/jrq3rq/status/1802513058541232262",
        imageSrc: Xarticle8,
        title:
          "Unlocking Leadership Potential: The Power of Shadow Work in Business",
        showButton: false,
        topics: ["psychology", "business", "leadership"],
      },
      {
        id: 10,
        link: "https://x.com/jrq3rq/status/1804312930244284748",
        imageSrc: Xarticle10,
        title:
          "How Extroverts Lead the World Built by Introverts: The Yin and Yang of Human Personalities",
        showButton: false,
        topics: ["psychology", "personality"],
      },
    ],
    []
  ); // Empty dependency array means this will only be computed once

  const topics = useMemo(() => {
    const allTopics = articles.flatMap((article) => article.topics);
    const uniqueTopics = [...new Set(allTopics)].sort();
    return ["all", ...uniqueTopics];
  }, [articles]);

  const filteredArticles = useMemo(() => {
    if (selectedTopic === "all") return articles;
    return articles.filter((article) => article.topics.includes(selectedTopic));
  }, [articles, selectedTopic]);

  const openModal = (article) => {
    setActiveArticle(article);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setActiveArticle(null);
  };

  const handleArticleClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  const formatTopicLabel = (topic) => {
    if (topic === "all") return "All";
    if (topic.toLowerCase() === "ai") return "AI";
    if (topic.toLowerCase() === "ar") return "AR";
    return topic.charAt(0).toUpperCase() + topic.slice(1);
  };

  return (
    <>
      <BackgroundWrapper />
      <Container id="Xarticles">
        <FilterContainer>
          <FilterDropdown
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
          >
            {topics.map((topic) => (
              <option key={topic} value={topic}>
                {formatTopicLabel(topic)}
              </option>
            ))}
          </FilterDropdown>
        </FilterContainer>
        <GridWrapper>
          {filteredArticles.map((article) => (
            <Article
              key={article.id}
              onClick={() => handleArticleClick(article.link)}
            >
              <ArticleImage
                src={article.imageSrc}
                alt={`Article ${article.id}`}
              />
              <ArticleOverlay>{article.title}</ArticleOverlay>
              <ArticleButton
                show={article.showButton}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(article);
                }}
              >
                Read
              </ArticleButton>
            </Article>
          ))}
        </GridWrapper>
      </Container>
      {activeArticle && (
        <ArticleModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          bottomImage={activeArticle.bottomImage}
        >
          <div dangerouslySetInnerHTML={{ __html: activeArticle.content }} />
        </ArticleModal>
      )}
    </>
  );
};

export default XArticlesComponent;
