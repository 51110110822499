import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RiCloseLine } from "react-icons/ri";
import ImageLogo from "../assets/logo192.png";
import TWOflyWave from "../assets/2flyWave.gif";
import UnderConstruction from "../assets/UnderConstruction.png";
import UnderConstructionPunk from "../assets/UnderConstructionPunk.png";
import UnderConstructio from "../assets/UnderConstructio.png";
import AboutMeGifWave from "../assets/AboutMeGifWave.gif";
import { Link } from "react-router-dom";
import ChatWindow from "./components/ChatWindow";
import { lighten } from "polished";

const Mychatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const handleIconClick = () => {
    setIsOpen(!isOpen); // Toggle chatbot visibility
    setIsModalOpen(!isModalOpen); // Toggle modal visibility
    setFadeIn(!isModalOpen); // Toggle fade animation
  };

  useEffect(() => {
    if (!isModalOpen) {
      setTimeout(() => {
        setFadeIn(false);
      }, 1000); // Adjust the delay to match the transition duration in CSS
    }
  }, [isModalOpen]);

  const fadeClass = fadeIn ? "fade-in" : "";

  return (
    <>
      <ChatbotContainer isOpen={isOpen}>
        <ChatbotBubble onClick={handleIconClick}>
          <StyledImage src={TWOflyWave} alt="Profile" />
        </ChatbotBubble>
      </ChatbotContainer>
      <ChatbotButtonContainer isOpen={isOpen}>
        <ChatbotDiv />
      </ChatbotButtonContainer>
      {isModalOpen && (
        <ModalContainer className={fadeClass}>
          <ModalContent>
            <AlternativeChatbotButton2
              isOpen={isOpen}
              onClick={handleIconClick}
            />
            <ChatWindow />
          </ModalContent>
        </ModalContainer>
      )}
    </>
  );
};

export default Mychatbot;

const ChatbotContainer = styled.div`
  position: fixed;
  bottom: ${({ isOpen }) => (isOpen ? "50px" : "30px")};
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  transition: bottom 0.3s ease-in-out;
  z-index: 9999;
`;

const ChatbotDiv = styled.div`
  width: 90px;
  font-size: 12px;
  padding: 10px 10px;
  background-color: #44fd47;
  border: none;
  color: #000000;
  border-radius: 10px 10px 0px 0px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background-color: #000000;
  /* border: 1px solid #44fd47; */
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? "translateY(-20px)" : "none")};
  z-index: 2;
`;

const ChatbotButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: transform 0.5s ease-in-out;
  z-index: 10000;
  transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(100%)")};
  border-width: 0.5px 0.5px 0px 0.5px;
  border-style: solid;
  border-color: #000;
  border-radius: 10px 10px 0px 0px;
`;

const CloseButton = styled.button`
  position: absolute;
  bottom: 20px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: transparent;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px; /* Set the width in pixels */
  height: 225px; /* Set the height in pixels */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  &.fade-in {
    opacity: 1;
    transition: opacity 1s, transform 1s;
  }

  &.fade-in.fade-out {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1s, transform 1s;
  }
`;

const ContentText = styled.div`
  height: auto;
  width: 450px;
  position: relative; /* Add this */
  z-index: 2; /* Ensure the ContentText is on top */
  @media (max-width: 480px) {
    /* Styles for small screens */
    min-height: 460px;
    max-width: 300px; /* Adjust the maximum width for small screens */
    border-width: 10px; /* Adjust the border width for small screens */
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    /* Styles for medium screens */
    max-width: 600px; /* Adjust the maximum width for medium screens */
    border-width: 15px; /* Adjust the border width for medium screens */
  }

  @media (min-width: 1025px) {
    /* Styles for large screens */
    max-width: 800px; /* Adjust the maximum width for large screens */
    border-width: 20px; /* Adjust the border width for large screens */
  }
`;
const FooterLinkLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3ddf3f;
    transition: 0.3s ease-out;
  }
`;

const BottomLink = styled(Link)`
  height: auto;
  width: 100%;
  padding: 10px 22px;
  /* max-width: 200px; */
  border-radius: 50px;
  color: #000000;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  & > * {
    /* margin-bottom: 10px; */
  }
  &:hover {
    color: #ffffff;
    background-color: #000000;
    cursor: pointer;
  }
`;

const P = styled.p`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const H1 = styled.div`
  font-size: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatbotButton = styled.button`
  padding: 10px 10px;
  background-color: pink;
  /* background-color: #45fe47; */
  border: none;
  color: #000000;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
`;

const AlternativeChatbotButton2 = styled(RiCloseLine)`
  position: absolute;
  z-index: 1000; /* Set a high z-index value to ensure it's on top */
  top: 5px;
  right: 5px;
  font-size: 24px;
  cursor: pointer;
  color: #000; /* Change the color to black */
  background-color: ${lighten(0, "#43fe47")};
  border-top: 0px solid #000;
  border-right: 0px solid #000;
  border-bottom: 0px solid #000;
  border-left: 0px solid #000;
  border-radius: 8px 8px;
  @media (max-width: 480px) {
    border-top: 0px solid #000;
    border-right: 0px solid #000;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-radius: 2px 8px;
  }
`;

const AlternativeChatbotButton = styled(ChatbotButton)`
  background-color: #45fe47;
  color: #000000;
  width: 100%;
  border-radius: 8px 8px;
  /* border-radius: 44px 44px; */
  border: 1px solid #000;
  margin: 0px 10px 0px 10px;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  &:hover {
    background-color: ${lighten(0.2, "#43fe47")};
  }
`;

const ChatbotBubble = styled.div`
  /* background-color: #45fe47; */
  /* color: #333333; */
  border-radius: 50%;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  animation: glowAnimation 2s infinite;
  @keyframes glowAnimation {
    0%,
    100% {
      background-color: #f6f6f6f6; /* Start and end with the darker color */
      transform: scale(1);
    }
    45%,
    55% {
      background-color: #f6f6f6f6; /* Transition to the lighter color */
      transform: scale(1.1);
    }
  }
`;

const ModalContent = styled.div`
  height: 350px;
  /* background-color: pink; */
  background-color: #43fe47;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 14px;
  position: relative; /* Add this */
  border: 1px solid #000;
  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const ModalContentMain = styled.div`
  height: auto;
  position: relative; /* Add this */
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align the content to the bottom */
  border: 20px solid transparent; /* Default border */
  position: absolute;
  bottom: 0; /* Position the image at the bottom */
  left: 0;
  z-index: 10; /* Ensure the ModalImage is on top */
  @media (max-width: 480px) {
    /* Styles for small screens */
    max-width: 280px; /* Adjust the maximum width for small screens */
    border-width: 10px; /* Adjust the border width for small screens */
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    /* Styles for medium screens */
    max-width: 600px; /* Adjust the maximum width for medium screens */
    border-width: 15px; /* Adjust the border width for medium screens */
  }

  @media (min-width: 1025px) {
    /* Styles for large screens */
    max-width: 800px; /* Adjust the maximum width for large screens */
    border-width: 20px; /* Adjust the border width for large screens */
  }
`;
